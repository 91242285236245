import React, { Fragment, Component } from 'react'
import { ConnectedLoginForm } from 'components/Forms/Login'
import splitPage from 'app/hoc/splitPage'
import SEO from 'components/SEO'
import { getSeoUrlsByPage, PAGES } from 'pagesConfig/utils'

@splitPage
class LoginPage extends Component {
  render() {
    return (
      <Fragment>
        <SEO type="LOGIN" />
        <ConnectedLoginForm typeOfLogin="broker" />
      </Fragment>
    )
  }
}

export const getServerSideProps = getSeoUrlsByPage(PAGES.LOGOUT)

export default LoginPage
